<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="mb-2">
          CPU Load
        </b-card-title>
        <b-card-sub-title>This graph show the average load on the CPU over, 1 minute, 5 minutes and 15 minutes.</b-card-sub-title>
      </div>
      <!--/ title and subtitle -->

      <!-- datepicker -->
      <div class="d-flex align-items-center">
        <feather-icon
          icon="CalendarIcon"
          size="16"
        />
        <flat-pickr
          v-model="dateItem"
          :config="{ mode: 'single', dateFormat: 'd/m/Y', maxDate: new Date()}"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
          style="max-width: 123px;"
        />
      </div>

    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        v-if="series"
        type="line"
        height="400"
        :options="cpuChartOptions"
        :series="series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import flatPickr from 'vue-flatpickr-component'
import dateService from '../../../services/DateService'

const cpuChartOptions = {
  chart: {
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  markers: {
    strokeWidth: 1,
      strokeOpacity: 1,
      strokeColors: ['#D0D4DB'],
      colors: ['#ffc085'],
  },
  colors: [$themeColors.warning, $themeColors.primary, $themeColors.success],
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'straight',
  },
  grid: {
    xaxis: {
      lines: {
        show: true,
      },
    },
  },
  xaxis: {
    type: 'datetime',
    labels: {
      rotate: -15,
      rotateAlways: true,
      formatter(val, timestamp) {
        return dateService.chartTime(timestamp)
      },
    },
  },
  yaxis: {
    // opposite: isRtl,
  },
}

export default {
  components: {
    VueApexCharts,
    BCardHeader,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    flatPickr,
  },
  props: {
    stats: {
      required: true,
      type: Array,
    },
    day: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      cpuChartOptions,
      series: [],
    }
  },
  computed: {
    dateItem: {
      get() {
        return this.day
      },
      set(value) {
        if (value !== this.day) {
          this.$emit('dayChanged', value)
        }
      },
    },
  },
  watch: {
    stats() {
      this.createSeries()
    },
  },
  mounted() {
    this.createSeries()
  },
  methods: {
    createSeries() {
      if (Array.isArray(this.stats) === false) {
        return
      }
      const minSeries1 = []
      const minSeries5 = []
      const minSeries15 = []
      // 1 min load
      this.stats.forEach(item => {
        minSeries1.push([item.created_at_unix, item.cpu_load1])
        minSeries5.push([item.created_at_unix, item.cpu_load5])
        minSeries15.push([item.created_at_unix, item.cpu_load15])
      });

      this.series = [
        {
          name: '1 Min Load',
          data: minSeries1,
        },
        {
          name: '5 Min Load',
          data: minSeries5,
        },
        {
          name: '15 Min Load',
          data: minSeries15,
        },
      ]
    },
  },
}
</script>

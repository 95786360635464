const optionsDateTime = {
  month: '2-digit', day: 'numeric', hour: '2-digit', minute: '2-digit',
};
const optionsTime = {
  hour: '2-digit', minute: '2-digit',
};
export default {

  chartDateTime(timestamp) {
    return new Date(timestamp).toLocaleDateString('en-GB', optionsDateTime)
  },

  chartTime(timestamp) {
    return new Date(timestamp).toLocaleTimeString('en-GB', optionsTime)
  },
  todayISO() {
    return new Date().toISOString().split('T')[0]
  },
  todayUK() {
    return new Date().toLocaleDateString('en-GB', optionsDateTime)
  },
}

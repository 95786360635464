import axios from '@axios';
import HelperService from '@/services/HelperService'

const baseUrl = '/machines';

export default {
  get(id, filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}/${id}/stats${queryString}`);
  },
};

<style lang="scss">
.update-machine-modal{
  &__roles{
    max-height: 200px;
    overflow-y: auto;
  }
}
</style>

<template>
  <div class="update-machine-modal">
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(updateMachine)">
        <section class="mb-2">
          <validation-provider
            v-slot="validationContext"
            name="Name"
            :rules="{ required: true }"
          >
            <b-form-input
              v-model="editMachine.name"
              :state="getValidationState(validationContext)" class="mb-1" placeholder="Enter name"
            />

            <small class="text-danger">{{ validationContext.errors[0] }}</small>
          </validation-provider>
        </section>

        <section class="d-inline-flex full-width mt-2">
          <div class="ui-spacer" />
          <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
          <b-button variant="primary" type="submit">Update Machine</b-button>
        </section>
      </b-form>
    </validation-observer>

  </div>
</template>

<script>
import MachineService from '@/services/MachineService';

export default {
  components: {},
  props: {
    machine: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      editMachine: {
        name: '',
      },
    };
  },
  mounted() {
    this.editMachine = { ...this.machine }
  },
  methods: {
    updateMachine() {
      MachineService.update(this.machine.id, this.editMachine).then(() => {
        this.$toast.success('Updated machine successfully', {
          toastClassName: ['toast-std', 'success-toast'],
        });
        this.$emit('refresh', true);
      }).catch(err => {
        const res = err.response
        let errorText = 'Could not update machine, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>

import { Dispatcher } from './dispatcher';
import { Timer } from './timer';

export default class Ws {
  private disconnected: boolean;

  private customSocket: any | WebSocket;

  private socketUrl: string;

  private reconnectTimer: Timer;

  private dispatcher = new Dispatcher();

  private id: string = '';

  constructor() {
    this.disconnected = true;
    this.socketUrl = '';
    this.reconnectTimer = new Timer(() => {
      this.disconnect();
      this.connect();
    }, this.reconnectAfterMs);
  }

  public async initialise(country: string, path:string) {
    this.socketUrl = await this.getWSUrl(country);
    this.socketUrl = this.socketUrl + path
    this.connect();
  }

  public getId() {
    return this.id;
  }

  public async getWSUrl(country) {
    let url = 'wss://ws.purplemdm.com'
    if (country === 'uk') {
      url = 'wss://ws.purplemdm.com'
    } else if (country === 'aus') {
      url = 'wss://ws.aus.purplemdm.com'
    } else if (country === 'local') {
      url = 'ws://ws.localmdm.com:1323'
    }
    return url
  }

  reconnectAfterMs(tries: number) {
    return [1000, 2000, 5000, 10000][tries - 1] || 10000;
  }

  public connected(): boolean {
    return !this.disconnected;
  }

  /**
   * Send an event through the websocket connection
   * @param event
   * @param payload
   */
  public emit(event: string, payload: string) {
    if (this.disconnected) {
      //console.log("Can't emit as socket is disconnected");
      return;
    }

    const message = JSON.stringify({ type: event, payload: payload });
    this.customSocket.send(message);
  }

  /**
   * Send an event through the websocket connection
   * @param message
   */
  public directEmit(message: string) {
    if (this.disconnected) {
      //console.log("Can't emit as socket is disconnected");
      return;
    }

    // console.log("READY:" + this.customSocket.readyState)
    //
    // // readyState of 1 means `open`
    if (this.customSocket.readyState != 1) {
      // console.log(
      //   `Can't emit as socket is not ready. State = ${this.customSocket.readyState}`,
      // );
      return;
    }

    this.customSocket.send(message);
  }

  connect() {
    this.customSocket = new WebSocket(this.socketUrl);

    this.customSocket.onopen = () => {
      this.disconnected = false;
      this.reconnectTimer.reset();
      this.dispatcher.dispatch('connected', null);
    };

    this.customSocket.onclose = () => {
      //console.log('On close called:', event);
      this.disconnected = true;
      this.reconnectTimer.scheduleTimeout();
      this.dispatcher.dispatch('disconnected', null);
    };

    this.customSocket.onerror = () => {
      //console.log('An error occurred connecting: ', event);
    };

    this.customSocket.onmessage = (event: any) => {
      if (event.data === undefined) {
        return;
      }

      const message = JSON.parse(event.data);

      const { type } = message;
      const { payload } = message;

      if (type === 'welcome') {
        this.id = payload.clientId;

        // Let's emulate the first-in-room if set
        if (payload.first === true) {
          this.dispatcher.dispatch('first-in-room', null);
        }
      }
      this.dispatcher.dispatch(type, payload);
    };
  }

  /**
   * Accessor to the dispatcher: Register for event
   * @param eventName
   * @param callback
   */
  on(eventName: string, callback: any) {
    this.dispatcher.on(eventName, callback);
  }

  /**
   * Accessor to the dispatcher: Unregister for event
   * @param eventName
   * @param callback
   */
  off(eventName: string, callback: any) {
    this.dispatcher.off(eventName, callback);
  }

  // Specific

  disconnect() {
    this.customSocket.onclose = function () {};
    this.customSocket.close(1000);
  }

  close() {
    this.disconnect();
  }
}

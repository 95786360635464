<style lang="scss">

</style>

<template>
  <div class="machine-issue-modal">
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(submitIssue)">
        <section class="mb-2">

          <p class="mt-1 mb-2">Please use the form below to describe the issue with your machine.</p>

          <div>
            <label>Subject</label>
            <validation-provider
              v-slot="validationContext"
              name="Subject"
              :rules="{ required: true, max: 50 }"
            >
              <b-form-input
                v-model="issue.subject"
                :state="getValidationState(validationContext)" class="mb-1"
                placeholder="e.g Machine overheating after 30 minutes of use"
              />

              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
          </div>

          <div>
            <label>Severity</label>
            <validation-provider
              v-slot="validationContext"
              name="Group"
              :rules="{ required: true }"
            >
              <b-form-select v-model="issue.severity" class="mb-2" :state="getValidationState(validationContext)">
                <b-form-select-option value="" disabled>Choose a severity level</b-form-select-option>
                <b-form-select-option v-for="option in severities" :key="option.value" :value="option.value"
                                      class="text-capitalize"
                >
                  {{ option.label }}
                </b-form-select-option>
              </b-form-select>
            </validation-provider>
          </div>

          <div>
            <label>Please provide a full description of the error you are having</label>
            <validation-provider
              v-slot="validationContext"
              name="Description"
              :rules="{ required: true, max: 5000 }"
            >
              <b-form-textarea
                v-model="issue.description"
                :state="getValidationState(validationContext)" class="mb-1"
                placeholder="Enter a longer, and full description of the issue"
              />

              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
          </div>

          <div>
            <label>Are there any error messages or warnings shown?</label>
            <validation-provider
              v-slot="validationContext"
              name="Errors"
              :rules="{ required: false, max: 5000 }"
            >
              <b-form-textarea
                v-model="issue.errors"
                :state="getValidationState(validationContext)" class="mb-1"
                placeholder="Enter a list of error messages shown on the page"
              />

              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
          </div>

          <div>
            <label>Please explain any steps needed for us to be able to reproduce the error</label>
            <validation-provider
              v-slot="validationContext"
              name="Reproduction"
              :rules="{ required: false, max: 5000 }"
            >
              <b-form-textarea
                v-model="issue.reproduction"
                :state="getValidationState(validationContext)" class="mb-1"
                placeholder="Enter some instructions on how to reproduce the issue"
              />

              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
          </div>

        </section>

        <section class="d-inline-flex full-width mt-2">
          <div class="ui-spacer" />
          <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
          <b-button variant="primary" type="submit">Submit Issue</b-button>
        </section>
      </b-form>
    </validation-observer>

  </div>
</template>

<script>
import IssuesService from '@/services/IssuesService';
import helperService from '@/services/HelperService';

export default {
  components: {},
  props: {
    machineId: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      issue: {
        subject: '',
        description: '',
        errors: '',
        reproduction: '',
        severity: '',
      },
      severities: [
        { value: 'CRITICAL', label: 'Critical: Machine Unusable.  Multiple major functions are not working.' },
        { value: 'HIGH', label: 'High: Machine Use Severely Impaired. Major function is not working, but the machine is still usable.' },
        { value: 'MEDIUM', label: 'Medium: Machine Use Partially Impaired. Minor function is not working, or infrequent issues with a major function.' },
        { value: 'LOW', label: 'Low: Machine Fully Operational. Minor issue detected, preventative maintenance is require.' },
      ],
    };
  },
  mounted() {
  },
  methods: {
    async submitIssue() {
      try {
        const dto = {
            subject: this.issue.subject,
            description: this.issue.description,
            errors: this.issue.errors,
            reproduction: this.issue.reproduction,
            severity: this.issue.severity,
            model: 'machines',
            model_uid: this.machineId.toString(),
          }
        const { data } = await IssuesService.submitIssue(dto)
        await this.$router.push({ name: 'issue-submitted', params: { uid: data.id.toString() } });
        helperService.showNotfySuccess(this.$toast, 'Issue has been successfully submitted.')
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, 'An error occurred submitting the issue, please refresh and try again')
      } finally {
       this.$emit('close')
      }
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>

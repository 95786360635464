<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="mb-2">
          Machine Usage
        </b-card-title>
        <b-card-sub-title>THis graph shows the CPU, Memory, disk and INODE usage as a percentage of the machines capacity.</b-card-sub-title>
      </div>
      <!--/ title and subtitle -->

      <!-- datepicker -->
      <div class="d-flex align-items-center">
        <feather-icon
          icon="CalendarIcon"
          size="16"
        />
        <flat-pickr
          v-model="dateItem"
          :config="{ mode: 'single', dateFormat: 'd/m/Y'}"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
          style="max-width: 123px;"
        />
      </div>

    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        v-if="series"
        type="line"
        height="400"
        :options="cpuChartOptions"
        :series="series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import flatPickr from 'vue-flatpickr-component'
import dateService from '../../../services/DateService'

const cpuChartOptions = {
  chart: {
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  markers: {
    strokeWidth: 1,
      strokeOpacity: 1,
      strokeColors: ['#D0D4DB'],
      colors: ['#ffc085'],
  },
  colors: [$themeColors.warning, $themeColors.primary, $themeColors.success, $themeColors.danger],
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'straight',
  },
  grid: {
    xaxis: {
      lines: {
        show: true,
      },
    },
  },
  xaxis: {
    type: 'datetime',
    labels: {
      rotate: -15,
      rotateAlways: true,
      formatter(val, timestamp) {
        return dateService.chartTime(timestamp)
      },
    },
  },
  yaxis: {
    // opposite: isRtl,
  },
}

export default {
  components: {
    VueApexCharts,
    BCardHeader,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    flatPickr,
  },
  props: {
    stats: {
      required: true,
      type: Array,
    },
    day: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      cpuChartOptions,
      series: [],
    }
  },
  computed: {
    dateItem: {
      get() {
        return this.day
      },
      set(value) {
        if (value !== this.day) {
          this.$emit('dayChanged', value)
        }
      },
    },
  },
  watch: {
    stats() {
      this.createSeries()
    },
  },
  mounted() {
    this.createSeries()
  },
  methods: {
    createSeries() {
      if (Array.isArray(this.stats) === false) {
        return
      }
      const seriesCPU = []
      const seriesDiskUsed = []
      const seriesInodesUsed = []
      const seriesMemoryUsed = []
      // 1 min load
      this.stats.forEach(item => {
        seriesCPU.push([item.created_at_unix, item.cpu_percentage])
        seriesDiskUsed.push([item.created_at_unix, item.disk_used_percentage])
        seriesInodesUsed.push([item.created_at_unix, item.disk_inodes_used_percentage])
        seriesMemoryUsed.push([item.created_at_unix, item.mem_used_percentage])
      });

      this.series = [
        {
          name: 'CPU %',
          data: seriesCPU,
        },
        {
          name: 'Disk Used %',
          data: seriesDiskUsed,
        },
        {
          name: 'Inodes Used %',
          data: seriesInodesUsed,
        },
        {
          name: 'Memory Used %',
          data: seriesMemoryUsed,
        },
      ]
    },
  },
}
</script>

import axios from '@axios';
import HelperService from '@/services/HelperService'

const baseUrl = '/machines';

export default {
  list(id, filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}/${id}/commands${queryString}`);
  },
  create(id, dto) {
    return axios.post(`${baseUrl}/${id}/commands`, dto);
  },
};

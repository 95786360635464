/**
 * A Dispatcher Event
 */
export class DispatcherEvent {
  private eventName: string;
  private callbacks: any[];

  constructor(eventName: string) {
    this.eventName = eventName;
    this.callbacks = [];
  }

  registerCallback(callback: any) {
    this.callbacks.push(callback);
  }

  unregisterCallback(callback: any) {
    const index = this.callbacks.indexOf(callback);
    if (index > -1) {
      this.callbacks.splice(index, 1);
    }
  }

  fire(data: any) {
    const callbacks = this.callbacks.slice(0);
    callbacks.forEach(callback => {
      callback(data);
    });
  }
}

/**
 * Dispatcher for Events
 */
export class Dispatcher {
  private readonly events: { [k: string]: any };

  constructor() {
    this.events = {};
  }

  dispatch(eventName: string, data?: any) {
    const event = this.events[eventName];
    if (event) {
      event.fire(data);
    }
  }

  on(eventName: string, callback: any) {
    let event = this.events[eventName];
    if (!event) {
      event = new DispatcherEvent(eventName);
      this.events[eventName] = event;
    }
    event.registerCallback(callback);
  }

  off(eventName: string, callback: any) {
    const event = this.events[eventName];
    if (event && event.callbacks.indexOf(callback) > -1) {
      event.unregisterCallback(callback);
      if (event.callbacks.length === 0) {
        delete this.events[eventName];
      }
    }
  }
}

export class Timer {
  private readonly callback: () => void;
  private readonly timerCalc: (tries: number) => number;
  private timer: any;
  private tries: number;
  constructor(callback: () => void, timerCalc: (tries: number) => number) {
    this.callback = callback;
    this.timerCalc = timerCalc;
    this.timer = null;
    this.tries = 0;
  }

  reset() {
    this.tries = 0;
    clearTimeout(this.timer);
  }

  scheduleTimeout() {
    //console.log("Running schedule Timeout");
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.tries = this.tries + 1;
      this.callback();
    }, this.timerCalc(this.tries + 1));
  }
}

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
.manage-btn {
  float:right;
  margin-top: -10px;
}
</style>
<!--eslint-disable-->
<template>
  <div class="users-page">
    <section v-if="!loading">
      <div v-if="machine">
        <b-card class="" title="Manage Machine">
          Your hub for monitoring and managing your machine's activities.
          <b-dropdown id="dropdown-grouped" variant="primary" right text="Manage" class="manage-btn" >
            <b-dropdown-group id="dropdown-group-1" header="Machine Actions">
              <b-dropdown-item v-if="$can('Update', 'Machine')" @click="showMachineModal('modal-update')">
                Update Machine
              </b-dropdown-item>
              <b-dropdown-item v-if="$can('Update', 'Machine')" @click="showMachineModal('modal-poweroff-machine')">
                Power Off
              </b-dropdown-item>
              <b-dropdown-item v-if="$can('Update', 'Machine')" @click="showMachineModal('modal-reboot-machine')">
                Reboot
              </b-dropdown-item>
              <b-dropdown-item v-if="$can('Create', 'Issue')" @click="showMachineModal('modal-create-machine-issue')">
                Submit Issue
              </b-dropdown-item>
              <b-dropdown-item v-if="$can('Delete', 'Machine')" @click="showMachineModal('modal-delete')" variant="danger" >
                Delete Machine
              </b-dropdown-item>
            </b-dropdown-group>
          </b-dropdown>
        </b-card>
<!-- Tab 1 -->
          <b-tabs v-model="activeTab" content-class="my-1">
            <b-tab title="Info" active>
              <b-card-actions no-body :noActions=true>

                <b-card-header class="mb-2">
                  <div>
                    <b-card-title class="mb-2">
                      {{ machine.name ?  machine.name : machine.machine_id }}
                    </b-card-title>
                    <b-card-sub-title>
                      Updating the machine name or changing the key will only reflect once the command has been run on the machine itself.
                    </b-card-sub-title>
                  </div>
                </b-card-header>

                <div class="row mt-2">
                  <div class="col-md-6">
                    <table class="table table-striped">
                      <tbody>
                      <tr><td class="pr-1">Machine ID</td><td>{{machine.machine_id}}</td></tr>
                      <tr><td class="pr-1">CPU Model</td><td>{{machine.cpu_model }}</td></tr>
                      <tr><td class="pr-1">CPU Cores</td><td>{{machine.cpu_cores}} ({{machine.cpu_threads}} threads)</td></tr>
                      <tr><td class="pr-1">Usable RAM</td><td>{{ getRam() }}</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-md-6">
                    <table class="table table-striped">
                      <tbody>
                      <tr><td class="pr-0">Created</td><td>{{machine.created_at | formatDateTime}}</td></tr>
                      <tr><td class="pr-1">System Vendor</td><td>{{machine.system_vendor}}</td></tr>
                      <tr><td class="pr-1">Product</td><td>{{machine.product_name}}</td></tr>
                      <tr><td class="pr-1">Key</td><td>{{machine.key_info}}</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-md-4"></div>
                </div>
              </b-card-actions>
              <b-card no-body v-if="latestMachineStat">
                <b-card-header class="mb-2">
                  <div>
                    <b-card-title class="mb-2">
                      Latest Machine Summary
                    </b-card-title>
                    <b-card-sub-title>
                      A snapshot of your machine's performance and system information.
                    </b-card-sub-title>
                  </div>
                  <div>
                    Last seen: {{ machine.last_seen | formatDateTime }}  {{ machine.last_seen | isOnline }}
                  </div>
                </b-card-header>
                <b-card-body class="statistics-body">
                  <b-row>
                    <!-- CPU -->
                    <b-col xl="3" sm="6" class="mb-2 mb-xl-0">
                      <b-media no-body>
                        <b-media-aside class="mr-2">
                          <b-avatar size="48" variant="light-primary">
                            <feather-icon size="24" icon="CpuIcon" />
                          </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                          <h4 class="font-weight-bolder mb-0">
                            CPU Usage
                          </h4>
                          <b-card-text class="font-small-3 mb-0">
                            {{ latestMachineStat.cpu_percentage | round2dp }}%
                          </b-card-text>
                        </b-media-body>
                      </b-media>
                    </b-col>
                    <!-- No processes -->
                    <b-col xl="3" sm="6" class="mb-2 mb-xl-0">
                      <b-media no-body>
                        <b-media-aside class="mr-2">
                          <b-avatar size="48" variant="light-success">
                            <feather-icon size="24" icon="ActivityIcon" />
                          </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                          <h4 class="font-weight-bolder mb-0">
                            Processes
                          </h4>
                          <b-card-text class="font-small-3 mb-0">
                            {{ latestMachineStat.host_procs }}
                          </b-card-text>
                        </b-media-body>
                      </b-media>
                    </b-col>
                    <!-- Up-time -->
                    <b-col xl="3" sm="6" class="mb-2 mb-xl-0">
                      <b-media no-body>
                        <b-media-aside class="mr-2">
                          <b-avatar size="48" variant="light-info">
                            <feather-icon size="24" icon="ClockIcon" />
                          </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                          <h4 class="font-weight-bolder mb-0">
                            Up-time
                          </h4>
                          <b-card-text class="font-small-3 mb-0">
                            {{ latestMachineStat.host_up_time | howLongUptime }}
                          </b-card-text>
                        </b-media-body>
                      </b-media>
                    </b-col>
                    <!-- Boot time -->
                    <b-col xl="3" sm="6" class="mb-2 mb-xl-0">
                      <b-media no-body>
                        <b-media-aside class="mr-2">
                          <b-avatar size="48" variant="light-secondary">
                            <feather-icon size="24" icon="PowerIcon" />
                          </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                          <h4 class="font-weight-bolder mb-0">
                            Boot Time
                          </h4>
                          <b-card-text class="font-small-3 mb-0">
                            {{ latestMachineStat.host_boot_time | formatDateTimeUnix }}
                          </b-card-text>
                        </b-media-body>
                      </b-media>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
              <b-card-actions :noActions=true title="Additional Information" class="">
                <div class="row mt-2">
                  <div class="col-md-6">
                    <table class="table table-striped">
                      <tbody>
                      <tr><td class="pr-1">CPU Vendor</td><td>{{machine.cpu_vendor }}</td></tr>
                      <tr><td class="pr-1">Board Vendor</td><td>{{machine.board_vendor}}</td></tr>
                      <tr><td class="pr-1">Board Name</td><td>{{machine.board_name}}</td></tr>
                      <tr><td class="pr-1">Board Serial</td><td>{{machine.board_serial}}</td></tr>
                      <tr><td class="pr-1">Chassis Serial</td><td>{{machine.chassis_serial}}</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-md-6">
                    <table class="table table-striped">
                      <tbody>
                      <tr><td class="pr-1">Updated</td><td>{{machine.updated_at | formatDateTime}}</td></tr>
                      <tr><td class="pr-1">BIOS</td><td>{{machine.bios_vendor}}</td></tr>
                      <tr><td class="pr-1">BIOS Version</td><td>{{machine.bios_version}}</td></tr>
                      <tr><td class="pr-1">BIOS Release</td><td>{{machine.bios_release}}</td></tr>
                      <tr><td class="pr-1">Daemon Version</td><td>{{latestMachineStat ? latestMachineStat.daemon_version : "?" }}</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-md-4"></div>
                </div>
              </b-card-actions>
            </b-tab>
<!-- Tab 2 -->
            <b-tab title="Commands">
              <b-card-actions v-if="$can('Update', 'Machine')" @refresh="refreshMachineCommands()" no-body action-refresh class="mb-1" title="Command Log"  ref="machineCommandsTable">
                <b-card-body>
                  <b-card-sub-title>Track and review your command history.</b-card-sub-title>
                </b-card-body>
                <template>
                  <b-table
                    class="data-table"
                    hover
                    :items="machineCommands"
                    :fields="commandsTableHeaders"
                    :current-page="currentPage"
                    :per-page="0"
                    show-empty
                    :responsive="true"
                  >
                    <template #cell(command_name)="data">
                      {{data.item.command_name }}
                    </template>
                    <template #cell(status)="data">
                    <span v-if="data.item.status === 'pending'">
                      <b-avatar variant="light-primary">
                        <feather-icon icon="LoaderIcon" />
                      </b-avatar> Pending
                    </span>
                      <span v-if="data.item.status === 'success'">
                      <b-avatar variant="light-success">
                        <feather-icon icon="CheckCircleIcon" />
                      </b-avatar> Success
                    </span>
                      <span v-if="data.item.status === 'error'">
                      <b-avatar variant="light-danger">
                        <feather-icon icon="XCircleIcon" />
                      </b-avatar> Error
                    </span>
                    </template>
                    <template #cell(processed_at)="data">
                      <span v-if="data.item.status==='pending'">-</span>
                      <span v-else>{{ data.item.processed_at | formatDateTime }}</span>
                    </template>
                    <template #cell(actions)="data">
                      <i class="fas fa-ellipsis-v"></i>
                    </template>
                  </b-table>
                  <b-row>
                    <b-col md="6" class="my-1" v-if="total_count > commandFilters.page_size">
                      <b-pagination @change="getMoreCommands" :total-rows="total_count" :per-page="commandFilters.page_size" v-model="currentPage" class="my-0"/>
                    </b-col>
                    <b-col class="ml-2 mt-1" v-if="total_count === 0">
                      <p >No results</p>
                    </b-col>
                  </b-row>
                </template>
              </b-card-actions>
            </b-tab>
<!-- Tab 3 -->
            <b-tab title="CPU Load">
                <chart-cpu-load :stats="stats" :day="day" @dayChanged="dayChanged" />
            </b-tab>
<!-- Tab 4 -->
            <b-tab title="Machine Usage" >
                <chart-machine-usage :stats="stats" :day="day" @dayChanged="dayChanged" />
            </b-tab>

            <b-tab title="Screen" v-on:click="getScreenshot" >
              <b-card-actions no-body :noActions=true>

                <b-card-header>
                  <div>
                    <b-card-title class="mb-2">
                      {{ machine.name ?  machine.name : machine.machine_id }}

                      <b-button type="submit" class="btn-sm" variant="primary" @click="getScreenshot" style="position: absolute; right: 10px;">
                        Get Screen
                      </b-button>
                    </b-card-title>

                  </div>
                </b-card-header>

                <div class="row">
                  <div class="col-md-12">
                    <img v-bind:src="screengrab" v-if="screengrab" class="col-12" alt="screen grab" />
                  </div>
                </div>
              </b-card-actions>
            </b-tab>
          </b-tabs>
      </div>

      <div v-else>No Machine found</div>

      <b-modal  id="modal-delete" title="Delete machine" modal-class="modal-danger" hide-footer>
        <delete-modal
          v-if="machine"
          @close="closeModals()" @delete="deleteMachine(machine)"
          :subtitle="machine.machine_id"
          title="Are you sure you would like to delete this Machine?" />
      </b-modal>

      <b-modal id="modal-update" title="Update Machine" hide-footer>
        <MachineUpdateModal v-if="machine" @refresh="refresh()" @close="closeModals()" :machine="machine"></MachineUpdateModal>
      </b-modal>

      <b-modal id="modal-poweroff-machine" title="Power Off" hide-footer>
        <warning-modal title="Are you sure you wish to power off this device?" ok-variant="primary"
                       @ok="createMachineCommand('poweroff')"
                       @close="closeModals()"/>
      </b-modal>

      <b-modal id="modal-reboot-machine" title="Reboot" hide-footer>
        <warning-modal title="Are you sure you wish to reboot this device?" ok-variant="primary"
                       @ok="createMachineCommand('reboot')"
                       @close="closeModals()"/>
      </b-modal>

      <b-modal id="modal-create-machine-issue" title="Submit a Machine Issue" hide-footer size="lg">
        <machine-issue-modal ok-variant="primary"
                            :machineId="machine.id"
                            @close="closeModals()"/>
      </b-modal>

    </section>

    <section v-else>
      <b-spinner variant="primary" label="Loading group"></b-spinner>
    </section>

  </div>
</template>
<!--eslint-enable-->

<script>
import {
 BCard, BCardHeader, BCardSubTitle, BCardText, BCardTitle,
} from 'bootstrap-vue';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import DeleteModal from '@/components/modals/DeleteModal.vue';
import MachineUpdateModal from '@/views/machines/view/MachineUpdateModal.vue';
import ChartCpuLoad from '@/views/machines/view/ChartCpuLoad.vue';
import ChartMachineUsage from '@/views/machines/view/ChartMachineUsage.vue';
import MachineIssueModal from '@/views/machines/view/MachineIssueModal.vue';
import MachineService from '../../../services/MachineService';
import MachineStatsService from '../../../services/MachineStatsService';
import dateService from '../../../services/DateService';
import MachineCommandsService from '../../../services/MachineCommandsService';
import WarningModal from '../../../components/modals/WarningModal.vue';
import Ws from "@/libs/ws/ws";

export default {
  name: 'MachineView',
  components: {
    MachineIssueModal,
    BCardText,
    BCardHeader,
    BCard,
    BCardTitle,
    BCardSubTitle,
    ChartMachineUsage,
    DeleteModal,
    BCardActions,
    MachineUpdateModal,
    ChartCpuLoad,
    WarningModal,
  },
  props: {
    id: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      activeTab: 0,
      loading: true,
      machine: null,
      stats: [],
      latestMachineStat: null,
      machineCommands: [],
      day: dateService.todayUK(), // dd/mm/yyyy
      filters: {
        day: dateService.todayISO(), // yyyy-mm-dd
      },
      commandsTableHeaders: [
        {
          key: 'command_name',
          label: 'Command Name',
        },
        {
          key: 'status',
          label: 'status',
        },
        {
          key: 'processed_at',
          label: 'Processed At',
        },
        {
          key: 'actions',
          label: '',
          class: 'text-right',
        },
      ],
      commandFilters: {
        page: 0,
        page_size: 20,
      },
      currentPage: 1,
      total_count: 0,
      ws: null,
      screengrab: null,
    };
  },
  mounted() {
    this.getMachine();
    this.getStats();
    this.getMachineCommands();
  },
  async created() {
    const countryFromStorage = localStorage.getItem('country')
    this.ws = new Ws();

    this.ws.on("machine-capture", payload => {
      this.screengrab = payload
    });

    await this.ws.initialise(countryFromStorage, `/users/machine/${this.id}`)

  },
  destroyed() {
    this.ws.close()
    this.ws = null; // to prevent memory leaks
  },
  methods: {
    refreshGroup() {
      this.closeModals();
      this.getMachine();
    },
    closeModals() {
      this.$bvModal.hide('modal-delete');
      this.$bvModal.hide('modal-update');
      this.$bvModal.hide('modal-reboot-machine');
      this.$bvModal.hide('modal-poweroff-machine');
      this.$bvModal.hide('modal-create-machine-issue');
    },
    showMachineModal(modalName) {
      this.$bvModal.show(modalName);
    },
    getMachine() {
      this.loading = true;
      MachineService.get(this.id).then(res => {
        this.machine = res.data;
        if (this.machine.machine_stats) {
          this.latestMachineStat = this.machine.machine_stats;
        }
      }).catch(err => {
        const res = err.response
        let errorText = 'Could not get machine, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      }).finally(() => {
        this.loading = false;
      });
    },
    async getStats() {
      try {
        const machineStats = await MachineStatsService.get(this.id, this.filters)
        this.stats = machineStats.data
      } catch (err) {
        this.stats = [];
        const res = err.response
        let errorText = 'Could not get machine, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      }
    },
    getScreenshot() {
      this.ws.emit('screengrab', {})
    },
    dayChanged(newDate) {
      const [day, month, year] = newDate.split('/', 3)
      this.filters.day = `${year}-${month}-${day}`
      this.day = newDate
      this.getStats()
    },
    deleteMachine() {
      MachineService.delete(this.machine.id).then(() => {
        this.$toast.success(`${this.machine.name} Deleted Successfully`, {
          toastClassName: ['toast-std', 'success-toast'],
        });
        this.$router.push({ name: 'machines' });
      }).catch(err => {
        const res = err.response
        let errorText = 'Could not delete machine, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }
        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      });
    },
    async getMachineCommands() {
      try {
        const { data } = await MachineCommandsService.list(this.id, this.commandFilters)
        this.machineCommands = data.data
        this.commandFilters.page = data.meta.page
        this.commandFilters.page_size = data.meta.page_size
        this.total_count = data.meta.total_count
      } catch (err) {
        this.machineCommands = [];
        const res = err.response
        let errorText = 'Could not get machine commands, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      }
    },
    async getMoreCommands(val) {
      this.$refs.machineCommandsTable.showLoading = true
      this.commandFilters.page = val - 1;
      await this.getMachineCommands()
      this.clearFilters()
      this.$refs.machineCommandsTable.showLoading = false
    },
    async refreshMachineCommands() {
      this.$refs.machineCommandsTable.showLoading = true
      await this.getMachineCommands()
      this.currentPage = 1
      this.$refs.machineCommandsTable.showLoading = false
    },
    clearFilters() {
      this.commandFilters = {
        page: 0,
        page_size: 20,
        search: '',
      }
    },
    async createMachineCommand(commandName) {
      try {
        const dto = { command_name: commandName }
        await MachineCommandsService.create(this.id, dto)
        await this.getMachineCommands()
        this.closeModals()
        this.activeTab = 1
        setTimeout(async () => {
          await this.getMoreCommands(this.currentPage)
        }, 10000)
      } catch (err) {
        const res = err.response
        let errorText = 'Could not send command to the machine, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
        this.closeModals()
      }
    },
    getRam() {
      if (this.machine.physical_ram) {
        return this.machine.physical_ram;
      }

      if(this.machine.machine_stats) {
        try {
          return (this.machine.machine_stats.mem_total/1024/1024/1024).toFixed(2) + " GB";
        } catch(e) {
          return ""
        }
      }


    },
    refresh() {
      this.closeModals();
      this.getMachine();
      this.getMachineCommands();
    },
  },
};
</script>
